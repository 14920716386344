<template>
	<div
		data-component="tag"
		:data-hover="hasItemId"
	>
		{{ title }}
	</div>
</template>

<script>
	export default {
		props: {
			list: {
				type: Array,
				default: () => []
			},
			id: {
				type: [String, Number],
				default: undefined
			},
			title: {
				type: String,
				default: ''
			}
		},
		computed: {
			hasItemId () {
				if (!this.id) {
					return false;
				}
				return true;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='tag'] {
		font-size: rem(16);
		position: relative;
		display: inline-block;
		padding: rem(4) rem(10);
		border-radius: 5px;
		margin-right: rem(10);
		color:$c-white;
		line-height: 1;
		background: #41b883;
		margin-bottom: rem(5);
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
		text-overflow: ellipsis;
		cursor: pointer;

		&:first-letter {
			text-transform: uppercase;
		}

		&[data-hover] {
			background: lighten(#41b883, 10%);
		}
	}
</style>
