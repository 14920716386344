<template>
	<div data-route>
		<page-header
			heading="Edit keyword"
		/>
		<div data-element="main">
			<validation-observer
				data-element="form"
				tag="form"
				ref="form"
				@submit.prevent="submit"
			>
				<input-field
					name="Keyword title"
					rules="required"
					type="text"
					v-model="keyword.title"
				/>

				<clickable-list
					v-if="hasFocuses"
					label="Focuses"
					:list="keyword.focus"
					@actionClick="editFocus"
				/>

				<actions
					:actions="getActions"
					@actionClick="onActionClick"
				/>
			</validation-observer>
		</div>
	</div>
</template>

<script>

	import PageHeader          from '@/components/ui/PageHeader';
	import InputField          from '@/components/forms/InputField';
	import Actions             from '@/components/ui/Actions';
	import ClickableList       from '@/components/focusesAndKeywords/ClickableList';
	import actionClick         from '@/mixins/actionClick';
	import invalidFormMessage  from '@/mixins/invalidFormMessage';
	import api                 from '@/services/api';

	export default {
		metaInfo: {
			title: 'Edit keyword'
		},
		components: {
			PageHeader,
			InputField,
			ClickableList,
			Actions
		},
		mixins: [actionClick, invalidFormMessage],
		data: () => ({
			keyword: {
				id: 			'',
				title: 		'',
				focus:		[]
			}
		}),
		computed: {
			getKeyword () {
				if (!this.getKeywordId ||
						!this.keyword ||
						!this.keyword.id) {
					return false;
				}
				return this.keyword;
			},
			getKeywordTitle () {
				if (!this.getKeyword?.title) {
					return false;
				}
				return this.getKeyword.title;
			},
			getKeywordId () {
				return this.$route.params.keywordId;
			},
			getBreadcrumbRoute () {
				return '/admin/keywords/';
			},
			getFocusRoute () {
				return '/admin/focuses';
			},
			hasFocuses () {
				return this.keyword?.focus?.length > 0;
			},
			getActions () {
				return {
					primary: [
						{
							text: 'Cancel',
							route: this.getBreadcrumbRoute
						}
					],
					secondary: [
						{
							text: 'Save',
							type: 'submit'
						},
						{
							text: 'Delete',
							actionId: 'delete'
						}
					].filter(Boolean)
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/keywords',
						text: 'Keywords'
					},
					{
						path: `/admin/focuses/${this.getKeywordId}`,
						text: this.getKeyword.title
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setKeyword();
		},
		methods: {
			editFocus (id) {
				this.$router.push(`${this.getFocusRoute}/${id}/`);
			},
			async setKeyword () {
				const keyword = await api.admin.getKeywordById({
					keywordId: this.getKeywordId
				});
				if (!keyword) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.keyword = {
					...this.keyword,
					...keyword
				};
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			},
			async submit () {
				const valid = await this.$refs.form.validate();
				if (!valid) {
					this.showInvalidFormMessage();
					return;
				}
				const keyword = await api.admin.updateKeywordById({
					keywordId: this.getKeywordId,
					keyword: { keyword : this.keyword.title }
				});
				if (!keyword) {
					return false;
				}
				await this.$router.push(this.getBreadcrumbRoute);
				this.$store.commit('ui/showNotification', {
					notification: 'The keyword has been updated'
				});
			},
			async delete () {
				const keyword = await api.admin.deleteKeywordById({
					keywordId: this.getKeywordId
				});
				if (!keyword) {
					return false;
				}
				await this.$router.push(this.getBreadcrumbRoute);
			}
		}
	};

</script>
