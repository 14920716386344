<template>
	<div
		data-component="clickable-list"
	>
		<h3
			v-if="hasLabel"
			data-element="label"
			v-html="label"
		/>
		<ul>
			<li
				v-for="item in list"
				:key="item.id"
				@click="click(item.id)"
			>
				<tag
					:title="item.title"
					:id="item.id"
				/>
			</li>
		</ul>
	</div>
</template>

<script>
	import Tag  from '@/components/focusesAndKeywords/Tag';

	export default {
		components: {
			Tag
		},
		props: {
			list: {
				type: Array,
				default: () => []
			},
			label: {
				type: String,
				default: ''
			}
		},
		computed: {
			hasLabel () {
				if (this.label) {
					return true;
				}
				return false;
			}
		},
		methods: {
			click (id) {
				if (!id) {
					return;
				}
				this.$emit('actionClick', id);
			}
		}
	};

</script>

<style lang="scss" scoped>
	[data-component='clickable-list'] {
		margin: 10px 0;
		ul {
			list-style: none;
			display: flex;
			width: 100%;
			flex-wrap: wrap;
		}
		[data-element='label'] {
			font-size:rem(12);
			padding-bottom:rem(9);
			display: inline-block;
			font-weight: 700;
		}
	}
</style>
